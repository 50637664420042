import { FaClock } from 'react-icons/fa'
import { Tooltip, Chip } from "@heroui/react"

const Tz = ({ challenge, isDark }) => {
  let desc = `The challenge start and end times were set in the ${challenge.timezone}${challenge.startTzAbbr ? ` or ${challenge.startTzAbbr}` : ''} time zone. The displayed date and time are adjusted to your local time zone.`
  return <Tooltip delay={500} content={desc} color='warning' classNames={{ content: 'max-w-[200px]'}}>
    <Chip variant='light' size='sm' startContent={<FaClock className={`${isDark ? 'text-gray-100' : 'text-gray-400'} ml-2`} style={{ fontSize: '16px' }} />}></Chip>
  </Tooltip>
}

export default Tz
