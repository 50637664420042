import { Chip } from "@heroui/react"

const ErrorChip = (props) => {
  return <div className='flex justify-center mb-4'><Chip 
    className={props.className || 'ml-2'}
    color={props.color || 'danger'}
    size='md'
    onClose={props.onClose ? onClose() : null}>
      {props.children}
  </Chip></div>
}

export default ErrorChip
